import React from "react"
import { motion } from "framer-motion"
import TextBlock from "./TextBlock"
import styles from "../../assets/css/projectView.module.scss"

const ProjectBlock = props => {
  const transition = {
    type: "tween",
    ease: [0.6, 0.01, 0.7, 1],
  }
  const variants = {
    overlay: {
      initial: {
        y: "0%",
      },
      enter: {
        y: "-100%",
        transition: transition,
      },
    },
    header: {
      initial: {
        y: "100%",
      },
      enter: {
        y: 0,
        transition: transition,
      },
      leave: {
        y: "-100%",
        transition: transition,
      },
    },
  }

  return (
    <motion.div
      className={styles.projectBlock}
      variants={variants.projectBlock}
    >
      {props.text !== undefined && (
        <>
          <div className={styles.textWrapper}>
            <motion.h1 variants={variants.header} id="title">
              {props.title}
            </motion.h1>
          </div>
          <TextBlock
            variants={variants.header}
            text={props.text}
            imageURL={props.imageURL}
            type={props.type}
            caption={props.caption}
            setTextPrep={props.setTextPrep}
          />
        </>
      )}
    </motion.div>
  )
}

export default ProjectBlock
