import React, { useContext, useState } from "react"
import { graphql, Link } from "gatsby"
import SEO from "../components/seo"
import { motion } from "framer-motion"
import styles from "../assets/css/projectView.module.scss"
import ProjectBlock from "../components/ProjectDetails/ProjectBlock"
import ImageBlock from "../components/ProjectDetails/ImageBlock"
import { Context } from "../context/Context"

export const query = graphql`
  query ProjectQuery($id: Int!) {
    strapiProject(strapiId: { eq: $id }) {
      strapiId
      title
      general
      problems
      gallery {
        url
        caption
      }
    }
  }
`

const variants = {
  wrapper: {
    enter: {
      transition: {
        staggerChildren: 0.03,
      },
    },
  },
  link: {
    initial: {
      y: "100%",
    },
    enter: {
      y: 0,
    },
    leave: {
      y: "-100%",
    },
  },
}

const Project = ({ data, ...props }) => {
  const project = data.strapiProject
  const [textPrep, setTextPrep] = useState(false)
  const { state } = useContext(Context)

  console.log(project)
  return (
    <>
      <SEO title={project.title} />
      <motion.div
        className={styles.wrapper}
        initial={"initial"}
        animate={textPrep && "enter"}
        exit={"leave"}
        variants={variants.wrapper}
      >
        <ProjectBlock
          title={"GENERAL"}
          text={project.general}
          imageURL={project.gallery[0].url}
          type={project.gallery[0].url.split(".")[3] === "webm" ? "vid" : "img"}
          caption={project.gallery[0].caption}
          setTextPrep={setTextPrep}
        />
        <ProjectBlock
          title={"PROBLEMS & SOLUTIONS"}
          text={project.problems}
          imageURL={project.gallery[1] !== undefined && project.gallery[1].url}
          type={
            project.gallery[1] !== undefined &&
            project.gallery[1].url.split(".")[3] === "webm"
              ? "vid"
              : "img"
          }
          caption={
            project.gallery[1] !== undefined && project.gallery[1].caption
          }
          setTextPrep={setTextPrep}
        />
        {project.extraDesc !== undefined && (
          <ProjectBlock
            title={"EXTRA"}
            text={project.extraDesc}
            imageURL={project.gallery[2].image.publicURL}
            type={
              project.gallery[2].url.split(".")[3] === "webm" ? "vid" : "img"
            }
            caption={project.gallery[2].caption}
            setTextPrep={setTextPrep}
          />
        )}
        {project.gallery.map((image, i) => {
          let type = image.url.split(".")[3] === "webm" ? "vid" : "img"
          return (
            (project.extraDesc === undefined ? i > 1 : i > 2) && (
              <ImageBlock
                key={i}
                type={type}
                src={image.url}
                caption={image.caption}
                capVar={variants.link}
              />
            )
          )
        })}
        <motion.div className={styles.linkWrapper}>
          <motion.div className={styles.link} variants={variants.link}>
            {props.pathContext.projectCount === project.strapiId ? (
              <Link to={`/projects/${state.projectTitles[1]}`}>
                first project
              </Link>
            ) : (
              <Link
                to={`/projects/${state.projectTitles[project.strapiId + 1]}`}
              >
                next project
              </Link>
            )}
          </motion.div>
        </motion.div>
      </motion.div>
    </>
  )
}

export default Project
